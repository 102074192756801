import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";


/* This example requires Tailwind CSS v2.0+ */
import {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {
    HandRaisedIcon,
    Bars3Icon,
    PlayIcon,
    XMarkIcon,
    PlusIcon,
} from '@heroicons/react/24/outline'
import {ChevronDownIcon} from '@heroicons/react/24/solid'
import FullLogoDark from "../../assets/FullLogoDark.svg"
import IconLogoDark from "../../assets/IconLogoDark.svg"


const icons = {
    "menu" : Bars3Icon,
    "play" : PlayIcon,
    "x" : XMarkIcon,
    "hand" : HandRaisedIcon,
    "plus" : PlusIcon
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function CompanyLogo() {
    // SVG content for the logo
    return (
        <div className="flex justify-start lg:w-0 lg:flex-1 px-5">
            <a href="/">
                <span className="sr-only">BiologIC</span>
                <FullLogoDark className="h-8 w-auto sm:h-10" />
            </a>
        </div>
    )
}

function CompanyLogoSmall() {
    // SVG content for the logo
    return (
        <div>
            <span className="sr-only">BiologIC</span>
            <IconLogoDark className="h-8 w-auto" />
        </div>
    )
}

let link_style = "text-base font-medium text-gray-500 hover:text-sky-900"

function CategoryItemSimple({category}) {
    // Return a simple link for a single link category
    let link = "/" + category.slug
    return (
        <a href={link} className={link_style}>
            {category.title}
        </a>
    )

}


function CategoryItemSimpleSmall({data}) {
    // Loop through the simple links based on sys id and Return a simple link for a single link category
    console.log(data)

    return(
        <nav className="grid gap-y-8">
            {data.items.map((item) => {
                    if (item.sys.contentType.sys.id === "simpleNavCategory") {
                        let link = "/" + item.slug
                        return (
                            <a key={item.title} href={link} className={link_style}>
                                {item.title}
                            </a>

                        )
                    } else {
                        return null
                    }
                }
            )
            }
        </nav>
    )
}

function ComplexCategoryMenuItem({item}) {
    const Icon = icons[item.icon]
    let link = "/" + item.slug
    return (
        <a
            key={item.title}
            href={link}
            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
        >

            {/* Get the right logo */}
            <Icon className="flex-shrink-0 h-6 w-6 text-sky-900" aria-hidden="true"/>


            <div className="ml-4">
                <p className="text-base font-medium text-gray-900">{item.title}</p>
                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
            </div>
        </a>

    )
}

function ComplexCategoryMenuItemsSmall({data}) {
    // Loop through the Category Menu Items within the data and display them

    return(
        <nav className="grid gap-y-8">
            {data.items.map((item) => {
                    if (item.sys.contentType.sys.id === "complexCategoryItem") {
                        return (
                            <>
                                { item.items.map((it) => {
                                    const Icon = icons[it.icon]
                                    let link = "/" + it.slug
                                    return (
                                        <a
                                            key={it.title}
                                            href={link}
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >
                                            <Icon className="flex-shrink-0 h-6 w-6 text-sky-900" aria-hidden="true" />
                                            <span className="ml-3 text-base font-medium text-gray-900">{it.title}</span>
                                        </a>
                                    )})} </>
                        )
                    } else {
                        return null
                    }
                }
            )
            }
        </nav>
    )
}

function ComplexCategoryItem({category}) {
    // Formats a complex category

    return (
        <Popover className="relative ">
            {({open}) => (
                <>
                    <Popover.Button
                        className={classNames(
                            open ? 'text-sky-900' : 'text-gray-500',
                            link_style,
                            'group bg-slate-70 rounded-md inline-flex items-center '
                        )}
                    >
                            <span>{category.title}</span>
                            <ChevronDownIcon
                                className={classNames(
                                    open ? 'text-gray-600' : 'text-gray-400',
                                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden="true"
                            />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                            <div className="rounded-lg shadow-lg overflow-hidden">
                                     <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                    {/* Menu items */}
                                    {category.items.map((item) => (
                                        <ComplexCategoryMenuItem key={item.title} item={item} />
                                    ))}
                                </div>
                                {/* Menu Call to action */}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

// function MenuExtra({extras}) {
//     // The extra info at the bottom of a Menu Category
//
//     return (
//         <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
//             {extras.map((item) => {
//                 return (
//                     <div key={item.name} className="flow-root">
//                         <a
//                             href={item.href}
//                             className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
//                         >
//                             <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true"/>
//                             <span className="ml-3">{item.name}</span>
//                         </a>
//                     </div>
//                 )
//             })}
//         </div>
//     )
//
// }

function Category({data}) {
    // Route to category function dependent on complex or simple
    // Passes in a single category - if sys id = complex then... else simple
    if (data.sys.contentType.sys.id === "complexCategoryItem") {
        return (<ComplexCategoryItem category={data} /> )
    } else {
        return (<CategoryItemSimple category={data} /> )
    }
}

const MainNavigation = () => {
    const data = useStaticQuery(graphql`
    {
      contentfulMainNavigation {
         title
          items {
        ... on ContentfulComplexCategoryItem {
          title
          slug
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          items {
            title
            slug
            description
            icon
          }
        }
        ... on ContentfulSimpleCategoryItem {
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          slug
          title
        }
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
    }
  `)

    return (
        <div className="max-w bg-white">
            <Popover className="relative mx-auto z-10">
                <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10 ">
                    <CompanyLogo/>
                    <div className="-mr-2 -my- 2 md:hidden">
                        <Popover.Button
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                            <span className="sr-only">Open menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </Popover.Button>
                    </div>
                    <Popover.Group as="nav" className="hidden md:flex space-x-10">

                        {
                            data.contentfulMainNavigation.items.map((item) => (
                                    <Category key={item.title} data={item} />
                                )
                            )
                        }
                    </Popover.Group>

                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        {/*
                    <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                        Sign in
                    </a>
                    <a
                        href="#"
                        className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                        Sign up
                    </a> */}
                    </div>

                </div>

                {/* Start of shrunk Menu */}
                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-100 ">
                        <div className="rounded-lg shadow-lg bg-white divide-y-2 divide-gray-50 ">

                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between px-5">
                                    <CompanyLogoSmall />
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                                            <span className="sr-only">Close menu</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <ComplexCategoryMenuItemsSmall data={data.contentfulMainNavigation}/>
                                </div>
                            </div>
                            <div className="py-6 px-5 space-y-6">
                                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                    <CategoryItemSimpleSmall data={data.contentfulMainNavigation} />
                                </div>
                                <div>
                                    {/* <a
                                    href="#"
                                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    Sign up
                                </a>
                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    Existing customer?{' '}
                                    <a href="#" className="text-indigo-600 hover:text-indigo-500">
                                        Sign in
                                    </a>
                                </p> */}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>


                </Transition>
            </Popover>
        </div>
    )
}


export default MainNavigation