import * as React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";

const Footer = () => {
    const data = useStaticQuery(graphql`
      {
        contentfulFooter {
          footerText {
            raw
          }
        }
      }
    `)

    return (
        <footer className="bg-white">
            <div className="py-10 px-4 md:px-10 text-center">
                {renderRichText(data.contentfulFooter.footerText)}
            </div>
        </footer>
    )
}

export default Footer