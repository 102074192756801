import React from "react"
import { Provider } from "react-redux"

import createStore from "./src/state-management/createStore"
import {Layout} from "./src/components/layout";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";




// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore

    let persistor
        persistor  = persistStore(store)

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null} >
                <Layout>
                    {element}
                </Layout>
            </PersistGate>
        </Provider>
    )

}