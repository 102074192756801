import { configureStore} from "@reduxjs/toolkit"
import AsyncStorage from "@react-native-async-storage/async-storage"
import {combineReducers} from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import thunk from "redux-thunk"


import { bannerSlice } from "../components/helper-components/bannerSlice";

const reducers = combineReducers({
    banner: bannerSlice.reducer
})

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const createStore = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
})

export default createStore