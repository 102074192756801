import { createSlice } from "@reduxjs/toolkit"

// Reducer  for Banner Display

export const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        value: true
    },
    reducers: {
        dismissed: state => {
            state.value = false
        },
        active: state => {
            state.value = true
        }
    }
})

export const { dismissed, active } = bannerSlice.actions

export const selectBanner = state => state.banner.value

export default bannerSlice.reducer