import * as React from "react";
import MainNavigation from "./layout-components/main-navigation";
import Footer from "./layout-components/footer"
export const Layout = ({children}) => (
    <>
        <div className="">
            <MainNavigation/>
            <div className="contents">
                {children}
            </div>
            <Footer/>
        </div>
    </>
)